import React from 'react';
import '../components/layout.scss';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link } from 'gatsby';

const ContactPage = () => (
  <Layout>
    <SEO title="Plan du site" />

    <section>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1 className="static-h1 m0">Plan du site</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mb0">
          <div className="col-sm-2" />
          <div className="col-sm-8">
            <div className="card">
              <ul>
                <li>
                  <Link to="/">Accueil</Link>
                </li>
                <li>
                  <span>La société</span>
                  <ul>
                    <li>
                      <Link to="/notre-aventure">Notre aventure</Link>
                    </li>
                    <li>
                      <Link to="/chiffres-references">
                        Chiffres clés et Références
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/nos-engagements">Nos engagements</Link>
                </li>
                <li>
                  <span>Nos produits</span>
                  <ul>
                    <li>
                      <Link to="/grand-format">Grand Format</Link>
                    </li>
                    <li>
                      <Link to="/impression-tous-supports">
                        Impression tous supports
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/devis">Devis</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                <li>
                  <Link to="/mentions-legales">Mentions légales</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ContactPage;
